
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import VFormBuilder from '../../components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import AuthApiService from '@/api/http/AuthApiService';

@Component({
  components: { VFormBuilder, VWrapper },
})
export default class ResetPassword extends mixins(Notification) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; validate: () => Promise<boolean | boolean[]> };
  };

  get items() {
    return [
      {
        type: InputType.PasswordConfirmation,
        name: 'password',
        rules: 'required|password|min:8',
        hint: 'auth.form.passwordHint',
        label: 'auth.form.password',
      },
    ];
  }

  public submit() {
    this.$refs.form.validate().then((res: boolean | boolean[]) => {
      if (res) {
        const api: AuthApiService = new AuthApiService();
        const data: {
          email: string;
          password: string;
          passwordConfirmation: string;
          confirmation?: string;
          token?: string;
        } = this.$refs.form.form;
        data.confirmation = data.passwordConfirmation;
        data.token = this.$route.params.token;

        api
          // @ts-ignore
          .resetPassword(data)
          .then(() => {
            this.notifyInfo('auth.notification.resetPassword');
            this.$router.push({ name: 'start' });
          })
          .catch(() => {
            this.notifyError('auth.notification.resetPasswordError');
          });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
